import { useEffect, useState } from "react"

export default function useNow() {

    const [now, setNow] = useState(Date.now())

    useEffect(() => {
        setNow(Date.now);
        const id = setInterval(() => {
            setNow(Date.now);
        }, 500);

        return () => {clearInterval(id)};
    }, [setNow])

    return {
        now
    }
}
