import { useMemo } from "react";
import useNow from "./use-now";

const EVENT_START = new Date('2020-12-06T17:00:00.000Z');
export default function useEventStart() {
    const {now} = useNow();

    const delta = EVENT_START.getTime() - now;



    return useMemo(() => {
        const days = Math.floor(delta / 1000 / 60 / 60 / 24);
        const hours = Math.floor((delta - (days * 24 * 60 * 60 * 1000)) / 1000 / 60 /60);
        const minutes = Math.floor((delta - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60);
        const seconds = Math.floor((delta - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / 1000);

        return {
            days,
            hours,
            minutes,
            seconds
        }

    }, [delta]);
}
