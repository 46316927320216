import React from "react";
import styled from "styled-components";
import Choinka from "../../components/choinka";

type Props = {}

const Container = styled.div`
`;

const MainViewPage: React.FC<Props> = () => {
    return <Container>
        <Choinka />
    </Container>
}

export default MainViewPage;
