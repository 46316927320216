import React, { useReducer } from "react";
import styled from "styled-components";
import useMainCategory from "../../modules/items/hooks/use-main-category";
import ChoinkaSide from "./side";

type Props = {}

const Container = styled.div``;

const Choinka: React.FC<Props> = () => {

    const {sides, total, avaiable} = useMainCategory();
    const [side, change] = useReducer((value: number, next: boolean) => {
        if (next) {
            return (value + 1) % sides.length;
        } else {
            return (sides.length + value - 1) % sides.length;
        }
    }, 0)



    return <Container>
        <h3>Strona {side}</h3>

        {sides[side] && <ChoinkaSide index={sides[side].index} />}

        <p>
            Dostępne bombki (globalnie): {avaiable} / {total}
        </p>

        <nav>
            <button onClick={() => change(false)}>Lewo</button>
            <button onClick={() => change(true)}>Prawo</button>
        </nav>
    </Container>
}

export default Choinka;
