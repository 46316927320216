import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import DebugComponent from './components/debug';
import BuyPage from './routes/buy';
import CatalogPage from './routes/catalog';

import HomePage from './routes/home';
import MainViewPage from './routes/main-view';
import PreEventPage from './routes/pre-event';
import { getBuyRoute, getCatalogPageRoute, getMainViewRoute } from './routes/routes';

export default function App() {

    const eventStarted = false;

    return (
        <BrowserRouter>
            {eventStarted && <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path={getMainViewRoute()} component={MainViewPage} exact />
                <Route path={getCatalogPageRoute()} component={CatalogPage} exact />
                <Route path={getBuyRoute()} component={BuyPage} exact />

                <Route component={HomePage} />
            </Switch>}
            {!eventStarted && <Switch>
                <Route component={PreEventPage} exact />
                </Switch>}
            {/* <DebugComponent /> */}
        </BrowserRouter>
    )
}
