import makeStore from "@mborecki/react-create-store";
import { User } from "../user";
import reducer from "./reducer";

export type ConfigModuleState = {
    isLoading: boolean,
    serverTime: number,
    user?: User
}

const initConfigModuleState: ConfigModuleState = {
    isLoading: false,
    serverTime: Date.now()
}

export enum CONFIG_MODULE_ACTION {
    FETCH,
    FETCH_SUCCESS
}

export type ConfigModuleAction = {
    type: CONFIG_MODULE_ACTION,
    payload?: any
}

const [
    ConfigModuleProvider,
    useConfigModuleDispatch,
    useConfigModuleState
] = makeStore(reducer, initConfigModuleState)


export {
    ConfigModuleProvider,
    useConfigModuleDispatch,
    useConfigModuleState
}
