import React from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import useLogin from "../../modules/user/hooks/use-login";

type Props = {}

const Container = styled.div``

const LoginForm: React.FC<Props> = () => {

    const { register, handleSubmit } = useForm();
    const {login, isLoading} = useLogin();
    const onSubmit = useCallback((data) => {
        console.log(data);
        login({email: data.email});
    }, [login])

    return <Container>
        <h2>Formularz logowania</h2>
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                    Email:
                <input name='email' defaultValue={'worker@test.tt'} ref={register({ required: true })} />
                </label>
                {!isLoading && <input type="submit" />}
            </form>
        </div>
    </Container>
}

export default LoginForm;
