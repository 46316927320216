import React from "react";
import styled from "styled-components";

type Props = {}

const Container = styled.div`
`;

const NewsComponent: React.FC<Props> = () => {
    return <Container>
        <h2>Tu będą aktualnosci....</h2>
    </Container>
}

export default NewsComponent;
