import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import usePage from "../../modules/items/hooks/use-page";
import { getBuyRoute } from "../routes";

type Props = {}

const Container = styled.div``;

const CatalogPage: React.FC<Props> = () => {

    const {page} = useParams<{page: string}>()
    const {items} = usePage(parseInt(page));


    return <Container>
        <h2>Strona z bombkami {page}</h2>

        {items.map((item, index) => {
            return <div key={item.id}>
                {item.avaiable && <Link to={getBuyRoute(item.id)}>Bombka {item.id} DOSTEPNA</Link>}
                {!item.avaiable && <p >Bombka {item.id} NIEDOSTEPNA</p>}
            </div>
        })}
    </Container>
}

export default CatalogPage;
