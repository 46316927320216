import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useSide from "../../modules/items/hooks/use-side";
import { getCatalogPageRoute } from "../../routes/routes";

type Props = {
    index: number
}

const Container = styled.div`
a {
    display: block;
    width: 20%;
    float: left;
}
`;

const ChoinkaSide: React.FC<Props> = ({ index }) => {

    const { pages } = useSide(index);

    return <Container>
        <h2>Strona choinki: {index}</h2>
        {
            pages.map((page) => {
                return (
                    <div key={page.index}>
                        <Link to={getCatalogPageRoute(page.index)}>Strona {page.index} - {page.avaiable}/{page.total}</Link>
                    </div>
                )
            })
        }
    </Container>
}

export default ChoinkaSide;
