import { ConfigModuleAction, ConfigModuleState, CONFIG_MODULE_ACTION } from ".";


export default function ConfigReducer(state: ConfigModuleState, action: ConfigModuleAction): ConfigModuleState {

    const {type, payload} = action;

    switch(type) {
        case CONFIG_MODULE_ACTION.FETCH:
            return {
                ...state,
                isLoading: true
            }

        case CONFIG_MODULE_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: payload.user
            }

    }

    return {
        ...state
    }
}
