import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useBuy from "../../modules/items/hooks/use-buy";

type Props = {
    id: number
}

const Container = styled.div`
    label {
        display: block;
    }
`;

const ConfirmPopup = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    div {
        background: white;
        padding: 3rem;
    }
`;

const BuyForm: React.FC<Props> = ({ id }) => {

    const { buy } = useBuy();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [showPurchaseError, setShowPurchaseError] = useState(false);
    const [formData, setFormData] = useState<any>();



    const { register, handleSubmit } = useForm();
    const onSubmit = useCallback((data) => {
        setFormData(data);
        setShowConfirm(true);
    }, [setFormData, setShowConfirm]);

    const confirmPurchase = useCallback(() => {
        buy({...formData, item: id}, {
            onSuccess: () => {
                setShowSummary(true);
            },
            onError: () => {
                setShowPurchaseError(true);
            }
        })
        setShowConfirm(false);
    }, [buy, id, setShowConfirm, formData]);

    const cancelPurchase = useCallback(() => {
        setShowConfirm(false);
    }, [setShowConfirm]);

    return <Container>
        <h2>Kup Pan bombke! {id}</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
            <label>
                Imie i nazwisko <input type='text' name='name' ref={register({ required: true, maxLength: 255 })} />
            </label>
            {/* <label>
                Nazwisko <input type='text' name='surname' ref={register({ required: true, maxLength: 255 })} />
            </label>
            <label>
                Telefon <input type='text' name='phone' ref={register({ required: true, maxLength: 20, minLength: 8 })} />
            </label>
            <label>
                Stanowisko <input type='text' name='job' ref={register({ required: true, maxLength: 255 })} />
            </label>
            <label>
                ID Pracownika <input type='text' name='workerID' ref={register({ required: true, maxLength: 255 })} />
            </label>
            <label>
                Nazwa redakcji <input type='text' name='office' ref={register({ required: true, maxLength: 255 })} />
            </label>
            <label>
                Nazwa marzenia <input type='text' name='dreamTitle' ref={register({ required: true, maxLength: 255 })} />
            </label>
            <label>
                Opis marzenia <textarea name='dreamDescription' ref={register({ required: true, maxLength: 1500 })} />
            </label>
            <label>
                Szacunkowa wartość marzenia <input type='number' name='dreamValue' ref={register({ required: true, pattern: /^[0-9]+([,][0-9])?$/, max: 4000 })} />
            </label>
            <label>
                Deklarowana kwota <input type='number' name='price' ref={register({ required: true, pattern: /^[0-9]+([.,][0-9])?$/ })} />
            </label>

            <label>
                Deklarowana kwota <input type='number' name='price' ref={register({ required: true, pattern: /^[0-9]+([.,][0-9])?$/ })} />
            </label>
            <label>
                Deklarowana kwota <input type='number' name='price' ref={register({ required: true, pattern: /^[0-9]+([.,][0-9])?$/ })} />
            </label>
            <label>
                Deklarowana kwota <input type='number' name='price' ref={register({ required: true, pattern: /^[0-9]+([.,][0-9])?$/ })} />
            </label> */}
            <input type='submit' />
        </form>

        {showConfirm && <ConfirmPopup>
            <div>
                <h2>Czy potwierdzasz zakup bombki {id}</h2>
                <div>
                    <button onClick={confirmPurchase}>TAK</button>
                    <button onClick={cancelPurchase}>NIE</button>
                </div>
            </div>
        </ConfirmPopup>}

        {showSummary && <ConfirmPopup>
            <div>
                <h2>Kupiłeś bombke!</h2>
                <div>
                    <button onClick={() => setShowSummary(false)}>OK :)</button>
                </div>
            </div>
        </ConfirmPopup>}

        {showPurchaseError && <ConfirmPopup>
            <div>
                <h2>BŁĄD BŁĄD BŁAD !!!!!!</h2>
                <div>
                    <button onClick={() => setShowPurchaseError(false)}>OK :(</button>
                </div>
            </div>
        </ConfirmPopup>}

    </Container>
}

export default BuyForm;
